<template>
  <div class="tracesource-authorizationInfo">
    <SelectBuyWay
      :isShow="isShow"
      :worksInfo="AuthorizationInfo"
      @closeMeSelect="closeMeSelect"
      @produceAuthorization="produceAuthorization"
    ></SelectBuyWay>
    <!--内容溯源授权许可-->
    <el-dialog
      :modal="true"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="授权许可"
      :visible.sync="onlineAuthorization"
      class="onlineAuthorization"
    >
      <div class="content">
        <div class="content-item">
          <div class="content-label">著作权人：</div>
          <div class="content-text">{{ AuthorizationInfo.author }}</div>
        </div>
        <div class="content-item">
          <div class="content-label">作品名称：</div>
          <div class="content-text">{{ AuthorizationInfo.filename }}</div>
        </div>
        <div class="content-item">
          <div class="content-label">作品字数：</div>
          <div class="content-text">{{ AuthorizationInfo.wordNumber }}字符</div>
        </div>
        <div class="content-item">
          <div class="content-label">创作时间：</div>
          <div class="content-text">{{ AuthorizationInfo.publishTime }}</div>
        </div>
        <div class="content-item">
          <div class="content-label">电子数据指纹：</div>
          <div class="content-text">【 {{ AuthorizationInfo.md5 }} 】</div>
        </div>
        <div class="content-item">
          <div class="content-label">授权类型：</div>
          <div class="content-text">
            <!-- {{ AuthorizationInfo.authorizationType }} -->
            信息网络传播权
          </div>
        </div>
        <div class="content-item">
          <div class="content-label">授权时限：</div>
          <div class="content-text">
            {{
              AuthorizationInfo.authorizationTerm == 1
                ? "长期"
                : `${AuthorizationInfo.startTime}到${AuthorizationInfo.endTime}`
            }}
          </div>
        </div>
        <div class="content-item">
          <div class="content-label">授权价格：</div>
          <div class="content-text">
            <span v-if="AuthorizationInfo.setPrice === 0 && fileType === 1"
              >{{ AuthorizationInfo.wordNumber }}字符</span
            >
            <span v-else>{{ AuthorizationInfo.authorizePrice }}元</span>
          </div>
        </div>
        <div class="content-item">
          <div class="content-label">被授权主体：</div>
          <div class="content-text">{{ AuthorizationInfo.authorizedName }}</div>
        </div>
        <div class="content-item">
          <div class="content-label">授权使用范围：</div>
          <div class="content-text">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 5 }"
              v-model.trim="textarea1"
            >
            </el-input>
          </div>
        </div>
        <div class="notes">
          注：授权成功后、授权书、作品文本将同步发送至"智能网盘/系统文件/授权文件"。
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn" @click="sureAuthorization">生成授权书</div>
        <div class="footer-btn" @click="onlineAuthorization = false">取消</div>
      </div>
    </el-dialog>
    <!-- 扫码支付 -->
    <el-dialog
      :modal="false"
      @close="handleClosePaymentPopup"
      :close-on-click-modal="false"
      title="扫码支付"
      :visible.sync="paymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodedata.codeImg" alt="" />
        </div>
        <div class="price">
          应付款：<span
            >￥<span>{{ orderPrice }}</span></span
          >
        </div>
      </div>
    </el-dialog>
    <!-- 支付成功 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="paymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">
        作品电子版将发送至你的“<span>智能网盘/系统文件/授权</span>”。
      </div>
      <div class="footer">
        <div class="footer-btn" @click="paymentSuccessPopup = false">确定</div>
        <div
          class="footer-btn"
          @click="
            () => {
              paymentSuccessPopup = false;
              $store.commit('ChangeSideBarId', '55');
              $router.push({
                path: '/disk',
              });
            }
          "
        >
          查看网盘
        </div>
      </div>
    </el-dialog>
    <!-- 支付成功 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="SqpaymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">
        作品电子版和授权书将发送至你的“<span>智能网盘/系统文件/授权</span>”。
      </div>
      <div class="footer">
        <div class="footer-btn" @click="SqpaymentSuccessPopup = false">
          确定
        </div>
        <div
          class="footer-btn"
          @click="
            () => {
              SqpaymentSuccessPopup = false;
              $store.commit('ChangeSideBarId', '55');
              $router.push({
                path: '/disk',
              });
            }
          "
        >
          查看网盘
        </div>
      </div>
    </el-dialog>
    <!-- 授权字符支付弹窗 -->
    <el-dialog title="提示" :visible.sync="characterPayDialog" class="tips">
      <div class="content" v-if="curCharacter > characterSum">
        <div class="content-text">
          本次在线授权将扣减<span>{{ characterSum }}</span
          >个字符，字符包现有<span>{{ curCharacter }}</span
          >个字符。
        </div>
      </div>
      <div
        class="content"
        v-else
        :class="curCharacter < characterSum ? 'active' : ''"
      >
        <i class="content-icon"></i>
        <div class="content-text">
          本次在线授权将扣减<span>{{ characterSum }}</span
          >个字符，字符包现有<span>{{ curCharacter }}</span
          >个字符，<span>已不足。</span>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn" @click="payauthorizationCharacterPay">确定</div>
        <div class="footer-btn" v-if="curCharacter > characterSum">
          字符包快捷支付
        </div>
        <div class="footer-btn" v-else @click="buyCharPack">购买字符包</div>
      </div>
    </el-dialog>
    <!--授权完成弹框提示-->
    <transition name="authorize-trans">
      <div class="authorize-tips-content" v-if="dialogGeneAuthorizeSuccessful">
        <i class="icon"></i>
        <div class="desc">
          溯源已完成，溯源报告存放于个人“<span class="blue"
            >存证网 盘/系统文件/授权</span
          >
          ”，官方存储系统不 另行保存。
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  getWorkDetails,
  generateAuthorization,
  payOtherOrderPay,
  payAuthorizationCharacterPay,
  PaywechatOtherOrderQueryQrcode,
  checkInterestsNumber,
} from "api/traceTheSource";
import SelectBuyWay from "components/select-buy-way/SelectBuyWay";
export default {
  name: "TraceSourceAuthorizationInfo",
  data() {
    return {
      //授权许可数据
      AuthorizationInfo: {},
      //在线授权弹窗
      onlineAuthorization: false,
      //支付弹窗
      paymentPopup: false,
      //支付成功弹窗
      paymentSuccessPopup: false,
      SqpaymentSuccessPopup: false,
      //是否正在生产授权
      isGeneAuthorize: false,
      //显示授权生产成功弹窗
      dialogGeneAuthorizeSuccessful: false,
      //授权-字符支付弹窗
      characterPayDialog: false,
      //授权扣减字符
      characterSum: 0,
      //当前账户字符数
      curCharacter: 0,
      //定时器
      timer: null,
      closeTimer: null,
      // 支付 oid
      oid: "",
      // 支付二维码
      qrCodedata: {},
      // 支付价格
      orderPrice: "",

      //生成授权范围
      textarea1: "仅限个人使用",
      isAuthorization: false,
      //购买方式
      buyWay: "",
      isShow: false,
    };
  },
  components: {
    SelectBuyWay,
  },
  methods: {
    buyCharPack() {
      try {
        //eslint-disable-next-line
        OpenBrowser("https://www.writemall.com/CopyrightPracticeDetail/2");
      } catch (e) {
        console.log(e);
      }
      window.isifHaveBuyDialog();
    },
    // 在线授权
    async authorization(item) {
      // const md5 = "asd1231sq"
      const md5 = item.textMd5;
      const res = await getWorkDetails(md5);
      if (res.code === 200) {
        this.AuthorizationInfo = res.data;
        //打开自主选择页面
        this.isShow = true;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    //自主选择emit确认
    produceAuthorization(way) {
      this.isShow = false;
      this.buyWay = way * 1;
      if (way === 1) {
        this.isAuthorization = false;
        this.textarea1 = "仅限个人使用";
        this.sureAuthorization();
      } else {
        this.isAuthorization = true;
        this.textarea1 = "";
        this.onlineAuthorization = true;
      }
    },
    closeMeSelect() {
      this.isShow = false;
    },
    // 生成授权书
    async sureAuthorization() {
      if (this.textarea1 === "") {
        return this.$message({
          type: "error",
          message: "授权使用范围不能为空",
        });
      }
      if (this.isGeneAuthorize) {
        return this.$message({
          type: "warning",
          message: "正在生成...",
        });
      }
      this.isGeneAuthorize = true;
      const data = {
        md5: this.AuthorizationInfo.md5,
        range: this.textarea1,
        platform: 1,
        buyType: this.buyWay,
      };
      const res = await generateAuthorization(data);
      // console.log(res);
      if (res.code === 200) {
        // console.log(res);
        if (res.data.priceType === 1) {
          this.oid = res.data.otherOrderId;
          this.orderPrice = res.data.orderPrice;
          this.payotherOrderPay(res.data.otherOrderId);
        } else {
          // console.log(1);
          this.characterPayDialog = true;
          this.oid = res.data.otherOrderId;
          this.characterSum = res.data.orderPrice;
          this.getFontNumber(this.characterSum);
        }

        this.onlineAuthorization = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
      this.isGeneAuthorize = false;
    },
    // 获取字符数量
    async getFontNumber(sum) {
      const data = {
        deductNum: sum,
        type: 2,
      };
      const res = await checkInterestsNumber(data);
      if (res.code === 200 || res.code === 7002) {
        this.curCharacter = res.data;
        this.characterPayDialog = true;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 字符支付
    async payauthorizationCharacterPay() {
      const data = {
        oid: this.oid,
      };
      this.characterPayDialog = false;
      this.$store.commit("playLifeLoading", true);
      const res = await payAuthorizationCharacterPay(data);
      if (res.code === 200) {
        this.$emit("PaySuccess");
        setTimeout(() => {
          this.dialogGeneAuthorizeSuccessful = true;
          setTimeout(() => {
            this.dialogGeneAuthorizeSuccessful = false;
          }, 2000);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
      this.$store.commit("playLifeLoading", false);
    },
    // 授权生成支付二维码
    async payotherOrderPay(id) {
      let that = this;
      const data = {
        oid: id,
      };

      const res = await payOtherOrderPay(data);

      if (res.code === 200) {
        if (this.closeTimer) {
          clearTimeout(this.closeTimer);
          this.closeTimer = null;
        }

        this.paymentPopup = true;
        this.qrCodedata = res.data;

        this.closeTimer = setTimeout(() => {
          clearInterval(this.timer);
          this.paymentPopup = false;
        }, 180000);
        this.timer = window.setInterval(function () {
          that.paywechatOtherOrderQueryQrcode();
        }, 3000);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 查询微信二维码扫码状态
    async paywechatOtherOrderQueryQrcode() {
      const data = {
        oid: this.oid,
      };

      const res = await PaywechatOtherOrderQueryQrcode(data);
      if (res.code == 200) {
        this.$emit("PaySuccess");
        window.clearInterval(this.timer);
        this.paymentPopup = false;
        if (this.isAuthorization) {
          this.SqpaymentSuccessPopup = true;
        } else {
          this.paymentSuccessPopup = true;
        }
      }
    },
    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      window.clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
.tracesource-authorizationInfo {
  //授权许可
  .onlineAuthorization {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 600px;
      // height: 430px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 20px 0 0;
        text-align: center;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        .el-dialog__headerbtn {
          top: 10px;
          right: 10px;
          font-size: 20px;
        }
      }
      .el-dialog__body {
        padding: 20px 30px 20 20px;
        .content {
          line-height: 24px;
          .content-item {
            display: flex;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 24px;

            .content-text {
              flex: 1;
            }
          }
          .notes {
            font-size: $font-size-small;
            font-family: PingFang SC;
            font-weight: 400;
            color: $color-text-gray-s;
          }
        }
        .footer {
          display: flex;
          padding: 30px 120px 0;
          justify-content: space-between;
          .footer-btn {
            cursor: pointer;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
          }
        }
      }
    }
  }
  //授权字符支付弹窗
  .tips {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 20vh !important;
      width: 340px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 15px 39px;
        .content {
          flex: 1;
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          display: flex;
          span {
            color: #eb441e;
          }
          .content-icon {
            width: 31px;
            height: 18px;
            @include backgroundGroup("../../assets/images/del-error.png");
            transform: translateY(4px);
            margin-right: 9px;
          }
        }
        .active {
          margin-top: 20px;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }
  // 支付弹窗
  .payment {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            width: 110px;
            height: 110px;
            margin-top: 30px;
            img {
              width: 110px;
              height: 110px;
            }
          }
          .price {
            margin-top: 30px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
            span {
              color: #eb441e;
              span {
                font-size: $font-size-large;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  // 支付成功弹窗
  .paymentSuccess {
    ::v-deep .el-dialog {
      margin-top: 20vh !important;
      width: 340px;

      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 30px 39px;
        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;

          color: $color-text-black;
          line-height: 26px;
          span {
            color: $color-bg-heightlight;
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }
  //授权成功弹窗
  .authorize-tips-content {
    @include flex-between;
    align-items: normal;
    position: absolute;
    z-index: 2000;
    top: 8%;
    left: calc(50% - 175px);
    width: 351px;
    height: 63px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #4587ff;
    border-radius: 10px;
    .icon {
      position: relative;
      width: 21px;
      height: 21px;
      flex-shrink: 0;
      margin-right: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 21px;
        height: 21px;
        background: url("~assets/images/PromptSuccessful.png") no-repeat;
        background-size: 100%;
      }
    }
    .desc {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      .blue {
        color: #4587ff;
      }
    }
  }
}
</style>
