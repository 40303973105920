<template>
  <div class="select-buy-way">
    <el-dialog
      :visible.sync="isShow"
      :modal-append-to-body="false"
      :show-close="false"
      width="35%"
    >
      <div slot="title" class="header-title">
        <span>自主选择</span>
        <img
          :src="require('assets/images/close.png')"
          alt=""
          @click="$emit('closeMeSelect')"
        />
      </div>
      <div class="works-info">
        <img
          class="works-type"
          :src="require('assets/images/wps-white.png')"
          alt=""
        />
        <div class="works-title">
          {{ worksInfo.filename }}
        </div>
      </div>

      <div
        class="buy"
        v-show="worksInfo.price != 0"
        :class="{ 'is-selsct': currentSelect == '购买' }"
        @click="selectBuyWay('购买', 1)"
      >
        <div class="price">
          <span>购买: </span>
          <span>¥ {{ worksInfo.price }} </span>
        </div>
        <div class="explain">
          {{ worksInfo.priceText }}
        </div>
      </div>
      <div
        class="buy"
        v-show="worksInfo.authorizePrice != 0"
        :class="{ 'is-selsct': currentSelect == '授权' }"
        @click="selectBuyWay('授权', 0)"
      >
        <div class="price">
          <span>授权: </span>
          <span>¥ {{ worksInfo.authorizePrice }} </span>
        </div>
        <div class="explain">
          {{ worksInfo.authorizationText }}
        </div>
      </div>
      <div class="option">
        <div class="confirm" @click="confirmMeSeleect">确定</div>
        <div class="close" @click="$emit('closeMeSelect')">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    worksInfo: {
      type: Object,
      default: () => {},
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // isShow: true,
      currentSelect: "购买",
      buyWay: 1,
    };
  },
  methods: {
    selectBuyWay(item, way) {
      this.currentSelect = item;
      this.buyWay = way;
    },
    confirmMeSeleect() {
      this.$emit("produceAuthorization", this.buyWay);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-buy-way {
  // position: absolute;
  // top: 20%;
  // left: 50%;
  // width: 400px;
  // height: 360px;
  // padding: 15px;
  // // border: 1px solid red;
  // transform: translateX(-50%);
  // background: #fff;
  // box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  // border-radius: 4px;
  ::v-deep .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      // padding: 10px 10px;
      padding: 0 20px 20px 20px;
    }
  }
  .header-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .works-info {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    // height: 55px;
    .works-title {
      display: inline-block;
      // width: calc(100% - 52px);
      flex: 1;
      font-size: 14px;
      // height: 100%;
      // vertical-align: middle;
    }
    .works-type {
      width: 52px;
      height: 52px;
      // vertical-align: middle;
    }
  }
  .buy {
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 76px;
    cursor: pointer;
    margin: 20px 0;
    .price {
      // padding: 10px 0;
      padding-bottom: 10px;
      > span:first-child {
        font-size: 14px;
        font-weight: bold;
      }
      > span:last-child {
        color: #f15416;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .explain {
      color: #333333;
      font-size: 14px;
    }
  }
  .is-selsct {
    border: 1px solid #aac8ff;
  }
  .is-selsct::before {
    content: "";
    background-image: url("~assets/images/select-buy.png");
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 99;
  }
  .option {
    width: 100%;
    display: flex;
    div {
      flex: 1;
      text-align: center;
      cursor: pointer;
      color: #4587ff;
    }
  }
}
</style>
